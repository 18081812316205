import './App.css';
import {useState, useEffect, useMemo} from "react";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, analytics } from './firebase.js'
import moment from "moment";
import Confetti from "react-confetti";
import { logEvent } from "firebase/analytics";

const colorMap = {
    '♠': 'steelblue', // A darker shade of blue
    '♥': 'crimson', // A darker shade of red
    '♣': 'forestgreen', // A darker shade of green
    '♦': 'orange', // Replacing yellow with orange for better contrast
    '♚': 'slateblue', // A darker shade of lavender
    '♛': 'palevioletred', // A darker shade of pink
    '♞': 'teal', // A darker shade of light sea green
    '♜': 'tomato' // A darker shade of salmon
};

const Card = ({ card, handleCardClick }) => {
    const cardStyle = {
        backgroundColor: card.flipped ? colorMap[card.content] : 'transparent'
    };

    return (
        <button className={`card ${card.flipped ? 'flipped' : ''}`} style={cardStyle} onClick={() => handleCardClick(card)}>
            {card.flipped ? card.content : ' '}
        </button>
    );
};


const shuffleCards = (cardContents) => {
    let cards = cardContents
        .map((content) => ({ content, flipped: false }))

    return cards;
};

const resetCards = (cardContents) => {
    let cards = cardContents
        .map((content) => ({ content, flipped: false }))

    return cards;
};


const CountdownTimer = ({ duration, onCompletion }) => {
    const [remainingTime, setRemainingTime] = useState(duration);

    useEffect(() => {
        if (remainingTime <= 0) {
            onCompletion();
            return;
        }

        const interval = setInterval(() => {
            setRemainingTime(time => time - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [remainingTime, onCompletion]);

    return <div>{remainingTime}s</div>;
}

function App() {
    const [cards, setCards] = useState([]);
    const [flippedIndices, setFlippedIndices] = useState([]);
    const [checkingMatch, setCheckingMatch] = useState(false);
    const [solvedIndices, setSolvedIndices] = useState([])
    const [stage, setStage] = useState(0);
    const [showWinningModal, setShowWinningModal] = useState(false);
    const [showHowToModal, setShowHowToModal] = useState(true);
    const [showConfetti, setShowConfetti] = useState(false);
    const [ pulsing, setPulsing ] = useState(false)
    const [ copyText, setCopyText] = useState("Share your time, challenge your friends!")
    const [ showTimer, setShowTimer ] = useState(false)

    const [roundOneStartTime, setRoundOneStartTime] = useState(null);
    const [roundOneStopTime, setRoundOneStopTime] = useState(null);
    const [roundTwoStartTime, setRoundTwoStartTime] = useState(null);
    const [roundTwoStopTime, setRoundTwoStopTime] = useState(null);
    const [showRoundOneModal, setShowRoundOneModal] = useState(null);

    const [puzzle, setPuzzle] = useState(null);

    const [isLoading, setIsLoading] = useState(true)
    const [puzzleNumber, setPuzzleNumber] = useState(0)
    const [timeToMidnight, setTimeToMidnight] = useState(null)

    const [roundOneTime, setRoundOneTime] = useState(null);
    const [roundTwoTime, setRoundTwoTime] = useState(null);
    const [totalTime, setTotalTime] = useState(null);

    useEffect(() => {
        const today = moment();


        const storedDate = localStorage.getItem('lastSolvedDate');

        if (storedDate === today.format("MMDYYYY")) {
            const cachedRoundOneTime = localStorage.getItem('cachedRoundOneTime');
            const cachedRoundTwoTime = localStorage.getItem('cachedRoundTwoTime');
            const cachedTotalTime = localStorage.getItem('cachedTotalTime');

            setRoundOneTime(cachedRoundOneTime)
            setRoundTwoTime(cachedRoundTwoTime)
            setTotalTime(cachedTotalTime)

            setStage(6);
            showAllCards()
        }

        var given = moment("2024-01-16", "YYYY-MM-DD");
        var current = moment().startOf('day');

        //Difference in number of days
        const days = moment.duration(current.diff(given)).asDays() + 1;

        setPuzzleNumber(days)
        const fetchTodaysPuzzle = async () => {
            const q = query(collection(db, "dailyPuzzles"), where("date", "==", today.format("MMDYYYY")));

            try {
                const querySnapshot = await getDocs(q);

                console.log(querySnapshot)
                if (querySnapshot.empty) {
                    console.log('No matching documents for today.');
                    return;
                }

                querySnapshot.forEach(doc => {
                    setPuzzle(doc.data()); // Assuming you want to store the first matching document
                });
            } catch (error) {
                console.error("Error fetching document: ", error);
            }
        };

        const interval = setInterval(() => {
            const now = moment();
            const midnight = moment().endOf('day');
            const duration = moment.duration(midnight.diff(now));

            const formattedTimeLeft = `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
            setTimeToMidnight(formattedTimeLeft);
        }, 1000);

        fetchTodaysPuzzle().then(() => {
            setIsLoading(false);
        });

        logEvent(analytics, "View App")

        return () => { clearInterval(interval)}
    },[])

    useEffect(() => {
        // Initialize cards
        if (!puzzle) {
            return;
        }

        const theDieIsCast = shuffleCards(puzzle.puzzleArray)
        setCards(theDieIsCast); // Use more pairs and shuffle them
    }, [puzzle]);

    const showAllCards = () => {
        const cardClone = cards.map((card) => {
            card.flipped = true
            return card;
        })
        setCards(cardClone);
    }

    const hideAllCards = () => {
        const cardClone = cards.map((card) => {
            card.flipped = false
            return card;
        })
        setCards(cardClone);
    }

    useEffect(() => {
        if (roundOneStopTime && roundOneStartTime) {
            setRoundOneTime(roundOneStopTime.diff(roundOneStartTime, "seconds", true));
            localStorage.setItem('cachedRoundOneTime', roundOneStopTime.diff(roundOneStartTime, "seconds", true));
        }
        if (roundTwoStopTime && roundOneStartTime) {
            localStorage.setItem('cachedRoundTwoTime', roundTwoStopTime.diff(roundTwoStartTime, "seconds", true));
            setRoundTwoTime(roundTwoStopTime.diff(roundTwoStartTime, "seconds", true));
        }
        if (roundOneStopTime && roundOneStartTime && roundTwoStopTime && roundTwoStartTime) {
            setTotalTime((roundOneStopTime.diff(roundOneStartTime, "seconds", true) + (roundTwoStopTime.diff(roundTwoStartTime,"seconds", true))).toFixed(3));
            localStorage.setItem('cachedTotalTime', (roundOneStopTime.diff(roundOneStartTime, "seconds", true) + (roundTwoStopTime.diff(roundTwoStartTime,"seconds", true))).toFixed(3));
        }

    }, [roundOneStopTime, roundOneStartTime, roundTwoStopTime, roundTwoStartTime])

    useEffect(() => {
        if (stage === 2 && solvedIndices.length === 16) {
            setRoundOneStopTime(moment());
            setShowConfetti(true);
            increaseStage();
        }
        if (stage === 5 && solvedIndices.length === 16) {
            setRoundTwoStopTime(moment());
            setShowConfetti(true);
            increaseStage();
        }
    }, [solvedIndices])


    useEffect(() => {
        if (stage === 1) {
            showAllCards()
            setShowTimer(true)
        }
        if (stage === 2) {
            setRoundOneStartTime(moment())
            hideAllCards()
        }
        if (stage === 3) {
            setShowRoundOneModal(true)
            hideAllCards()
        }
        if (stage === 4) {
            setShowConfetti(false);
            setShowRoundOneModal(false)
            showAllCards()
            setSolvedIndices([])
        }
        if (stage === 5 ) {
            hideAllCards()
            setRoundTwoStartTime(moment())
        }
        if ( stage === 6 ) {
            localStorage.setItem('lastSolvedDate', moment().format("MMDYYYY"));
            setShowHowToModal(false)
            setShowWinningModal(true)
        }
    }, [stage])

    const increaseStage = (controlled) => {
        setPulsing(false);
        if (controlled && stage >= 1) {
            return;
        }
        setStage(stage + 1)
    }

    const copyClipboard = () => {
        if (navigator.share) {
            navigator.share({
                title: 'matchle.io',
                text: `matchle.io\nshuffle #${puzzleNumber}\n🏁🏁🏁\nR1: ${roundOneTime}s\nR2: ${roundTwoTime}s\nTotal: ${totalTime}s\n🏁🏁🏁`,
            })
        } else {
            navigator
                .clipboard
                .writeText(`matchle.io\nshuffle #${puzzleNumber}\n🏁🏁🏁\nR1: ${roundOneTime}s\nR2: ${roundTwoTime}s\nTotal: ${totalTime}s\n🏁🏁🏁`).then((r) => {
                setCopyText("Copied!")})
        }
    }

    const handleCardClick = (card, index) => {
        if (stage === 0) {
            setPulsing(true);
            return;
        }
        // Avoid any action if a match is being checked or the clicked card is already flipped
        if ((stage !== 2 && stage !== 5) || checkingMatch || card.flipped || flippedIndices.includes(index)) {
            return;
        }

        const newFlippedIndices = [...flippedIndices, index];
        setFlippedIndices(newFlippedIndices);

        // Simple logic to flip a card
        const updatedCards = cards.map((card, i) => {
            if (i === index) {
                return { ...card, flipped: true };
            }
            return card;
        });

        // If two cards are flipped, check for a match
        if (newFlippedIndices.length === 2) {
            const [firstIndex, secondIndex] = newFlippedIndices;
            if (updatedCards[firstIndex].content === updatedCards[secondIndex].content) {
                // It's a match

                setSolvedIndices([...solvedIndices, flippedIndices[0], flippedIndices[1]])
                setFlippedIndices([]);
            } else {
                // No match, flip the cards back after a short delay
                setCheckingMatch(true);
                setTimeout(() => {
                    updatedCards[firstIndex].flipped = false;
                    updatedCards[secondIndex].flipped = false;
                    setFlippedIndices([]);
                    setCheckingMatch(false);
                }, 200); // Adjust delay as needed
            }
        }

        setCards(updatedCards);
    };


    const getButtonContent = useMemo(() => {
        if (stage === 0) {
            return "Tap to Start"
        }
        if ( stage === 1 || stage === 4 ) {
            return <CountdownTimer duration={stage === 1 ? 5 : 3} onCompletion={() => {
                setShowTimer(false)
                increaseStage()
            }}/>
        }
        if (stage === 2 || stage === 5) {
            return "GO!"
        }
        if (stage === 3) {
            return `${roundOneStopTime.diff(roundOneStartTime, "seconds", true)}s`
        }
    }, [stage])

    return (
        <div>
            { !isLoading && showHowToModal &&
                <div className={"scrim"}>
                    <div className={"modal modal-fullscreen"}>
                        <div className={"how-to-modal"}>
                            <button className={"how-to cancel"} onClick={() => {setShowHowToModal(false)}}>X</button>
                            <div><strong>What is matchle?</strong></div>
                            <div className={"fact"}>
                                matchle is a daily competitive memory game. The puzzle is the same for every person on Earth each day, and the cards are shuffled at midnight.
                            </div>
                            <div><strong>How to play matchle:</strong></div>
                            <ol>
                                <li><strong>👀 Study the Cards:</strong> Once you click the Start button, you will have <strong>5 seconds</strong> to study the layout of the cards. Your goal is to remember the location of as many matching pairs as possible.</li>
                                <li><strong>🧭 Find the Pairs:</strong> After the 5 second study time, the cards will flip back over, and the timer starts! Find matching pairs by clicking on cards to flip them over. If you select two cards in a row that are a pair, they will stay facing up. If they don’t match, they will flip back over. Find all the matching pairs as quickly as you can!</li>
                                <li><strong>Round 2:</strong> After you find all the pairs, you will have one more shot. But in this second and final round, you will only have <strong>3</strong> seconds to study the cards before they flip back over. Can you beat your first time?</li>
                            </ol>
                            <div>Share your times, and beat your friends 🏁</div>
                            <hr />
                            <span>Suggestions or Inquiries? <a href="mailto:contact@matchle.io?subject=matchle%20feedback">Contact</a></span>
                        </div>
                    </div>
                </div>
            }
            { !isLoading && showRoundOneModal &&
                <div className={"scrim"}>
                    <div className={"modal"}>
                        <div className={"winning-modal-content"}>
                            <div className={"congratulations"}>Round 1 Complete!</div>
                            <div className={"finalTime"}><div className={"flipped-flag"}>🏁</div> {roundOneTime}s 🏁</div>
                            <div className={"congrats-byline"}>Great job! Now you’ll have one more chance to solve <strong>the same puzzle</strong>. But this time, you’ll only have 3 seconds to study the cards. How much faster can you go?</div>
                            <div className={"share"} onClick={() => increaseStage()}>
                                Start Round 2
                            </div>
                        </div>
                    </div>
                </div>
            }
            { !isLoading && showWinningModal &&
                <div className={"scrim"}>
                    <div className={"modal"}>
                        <div className={"winning-modal-content"}>
                            <div className={"congratulations"}>Complete!</div>
                            <div className={"congrats-byline"}>matchle #{puzzleNumber}</div>
                            <div className={"time"}><strong>R1:</strong> {roundOneTime}s</div>
                            <div className={"time"}><strong>R2:</strong> {roundTwoTime}s</div>
                            <div className={"finalTime"}><div className={"flipped-flag"}>🏁</div> Total: {totalTime}s 🏁</div>
                            <button className={"share"} onClick={copyClipboard}>
                                {copyText}
                            </button>
                            <div>Cards shuffled in: {timeToMidnight}</div>
                            <span>Suggestions or Inquiries? <a href="mailto:contact@matchle.io?subject=matchle%20feedback">Contact</a></span>
                        </div>
                    </div>
                </div>
            }
            { showConfetti && <Confetti
                initialVelocityY={-10}
                colors={['steelblue', 'crimson', 'forestgreen', 'orange', 'slateblue', 'palevioletred', 'teal', 'tomato']}
                numberOfPieces={500}
                recycle={false}
                run={showConfetti}
            />
            }
            {
                isLoading ? (
                    <div className="load-container">
                    <div className="grid-loader">
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                        <div className="grid-square"></div>
                    </div></div> ) : (
                        <>
                            <div className="game-container">
                                <div className="game-board">
                                        {cards.map((card, index) => (
                                            <Card key={index} card={card} flippedIndices handleCardClick={() => handleCardClick(card, index)} />
                                        ))}
                                </div>
                            </div>
                                <div className={'stage-button-container'}>
                                <button className={`stage-button${pulsing ? " pulsing" : ""}`} onClick={() => {increaseStage(true)}}>
                                    { getButtonContent }
                                </button>
                            </div>
                        </>
                )
            }
        </div>
    );
}

export default App;
